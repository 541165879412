import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Github, Linkedin, Twitter, Facebook } from "styled-icons/entypo-social"
import { Telegram, Messenger, Discord } from "styled-icons/boxicons-logos"
import { Protonmail, Keybase } from "styled-icons/simple-icons"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="About" />
      <h1>About me</h1>
      <StaticImage width={500} alt="Tom Stevens" src="../../content/images/tom.jpg" style={{ float: "right", marginLeft: 20, marginBottom: 20, maxWidth: "100%" }} />
      <p>Born in 1989, quickly found an interest in technical stuff. Published my first webpage in 2001 hosted on GeoCities, made in Microsoft Frontpage. From there on I went to writing HTML by hand and further on to create more dynamic pages in PHP. Shortly thereafter I created a couple of homebrew CMS's for personal projects utilizing PHP+MySQL. During this timeframe I created websites for myself and some associations but never made any money on it.</p>
      <p>From there on I went on to get an education as a test driver and then went to university to get my bachelor in Computer Science. 2011 I was first employed and has since then made websites professionaly as a Certified Sitecore Developer using C# as the backend language and a constantly evolving toolset for the frontend.</p>
      <p>I consider myself a full stack developer with very good knowledge in both frontend and backend coding, at the same time I am no stranger to DevOps and managing servers and app services on for example Azure.</p>
      <p>I live with my lovely wife Michelle and our two daughters Hailey and Enya in Ekeby which is situated just outside of Helsingborg in Sweden. Here we live in our house which there is always planned renovations on, these are primarely made by me as I consider myself a quite handy.</p>
      <p>With family and kids in the younger range (born '15 and '17) comes some limitations to having hobbies. But I would consider a pretty good list of my hobbies this:</p>
      <ul>
        <li>Games (PC or Switch are my current platforms)</li>
        <li>Board games</li>
        <li>Programming</li>
        <li>Home automation</li>
        <li>RC cars</li>
      </ul>
      <p>When I have free time now I often only work with my home automation stuff or play some games (Battlefield for FPS and Wreckfest for racing). I also try to excercise and stay fit, that has however taken some change because of Covid and inability to go to the gym.</p>

      <h2>Social media links:</h2>
      <a target="_blank" rel="nofollower noreferrer" href="https://github.com/webbson" aria-label="Github"><Github size={20} /> webbson</a><br />
      <a target="_blank" rel="nofollower noreferrer" href="https://twitter.com/webbson_" aria-label="Twitter"><Twitter size={20} /> webbson_</a><br />
      <a target="_blank" rel="nofollower noreferrer" href="https://www.linkedin.com/in/webbson/" aria-label="LinkedIn"><Linkedin size={20} /> webbson</a><br />
      <a target="_blank" rel="nofollower noreferrer" href="https://www.facebook.com/tom.stevens.se" aria-label="Facebook"><Facebook size={20} /> tom.stevens.se</a><br />
      <h2>Contact:</h2>
      <a target="_blank" rel="nofollower noreferrer" href="https://getsession.org" aria-label="Session"><StaticImage height={20} alt="Tom Stevens" src="../../content/images/session.webp"/></a> webbson<br />
      <a target="_blank" rel="nofollower noreferrer" href="https://keybase.io/webbson" aria-label="Keybase"><Keybase size={20} /> webbson</a><br />
      <a target="_blank" rel="nofollower noreferrer" href="https://cyph.app/#profile/webbson" aria-label="Cyph"><StaticImage height={20} alt="Tom Stevens" src="../../content/images/cyph.jpg"/> webbson</a><br />
      <a target="_blank" rel="nofollower noreferrer" href="https://telegram.me/webbson" aria-label="Telegram"><Telegram size={20} /> webbson</a><br />
      <a target="_blank" rel="nofollower noreferrer" href="https://m.me/tom.stevens.se" aria-label="Messenger"><Messenger size={20} /> tom.stevens.se</a><br />
      <a href="mailto:webbson@protonmail.ch" aria-label="Protonmail"><Protonmail size={20} /> webbson@protonmail.ch</a><br />
      <div><Discord size={20} /> webbson#1070</div>

      <h2>PGP Public key</h2>
      <div>Fingerprint:<br />
      B663 409E A043 92C3 1A6A 5416 099B BE1F E073 5726</div>
      <div>Key ID (64-bit):<br />
      099B BE1F E073 5726</div>

      <a href="/B663-409E-A043-92C3-1A6A-5416-099B-BE1F-E073-5726.pub.asc">Download</a>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
